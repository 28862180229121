export const environment = {
  BASE_API_URL: "https://alqasr-admin.dedicateddevelopers.us/api/",
  IMAGE_BASE_URL: "https://alqasr-admin.dedicateddevelopers.us/uploads/",
  // ELASTIC_SEARCH_ENDPOINT: 'https://search-subhrada-node-conkavx3o7ho3h76hwfnqafyae.ap-south-1.es.amazonaws.com',
  ELASTIC_SEARCH_ENDPOINT: "https://es.dedicatedresource.net/",
  BLOG_API_URL: "https://dedicateddevelopers.us/alqasr/wp-json/",
  production: true,
  BASE_URL:"https://alqasr-ui.dedicateddevelopers.us",
  PORTAL_BASE_URL: "https://portal.alqasr-ui.dedicateddevelopers.us",
  AWS_BASE_URL:"https://alqasr.s3.ap-south-1.amazonaws.com/property/"


  // BASE_API_URL: "http://10.2.0.97:1512/api/",
  // IMAGE_BASE_URL: "http://10.2.0.97:1512/uploads/",
  // // ELASTIC_SEARCH_ENDPOINT: 'https://search-subhrada-node-conkavx3o7ho3h76hwfnqafyae.ap-south-1.es.amazonaws.com',
  // ELASTIC_SEARCH_ENDPOINT: "https://es.dedicatedresource.net/",
  // BLOG_API_URL: "https://dedicateddevelopers.us/alqasr/wp-json/",
  // production: true,
  // BASE_URL:"https://alqasr-ui.dedicateddevelopers.us",
  // PORTAL_BASE_URL: "https://portal.alqasr-ui.dedicateddevelopers.us",
  // AWS_BASE_URL:"https://alqasr.s3.ap-south-1.amazonaws.com/property/"







  // BASE_API_URL: 'https://admin.myqasr.com/api/',
  // IMAGE_BASE_URL: 'https://admin.myqasr.com/uploads/',
  // ELASTIC_SEARCH_ENDPOINT: 'https://es.myqasr.com/',
  // PORTAL_BASE_URL: 'https://portal.myqasr.com/',
  // BLOG_API_URL: 'https://dedicateddevelopers.us/alqasr/wp-json/',
  // production: true,
};
