import { EventService } from './event.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envconfig';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})


export class ApiService {

  private BASE_API_URL = environment.BASE_API_URL;
  private BLOG_API_URL = environment.BLOG_API_URL;
  httpOptions: { headers: HttpHeaders; };
  TOKEN: string;

  constructor(
    private http: HttpClient,
    private event: EventService,
    private storage: StorageService,
    public router: Router
  ) {

    // this.event.isLogin.subscribe((isLogin: boolean) => {
    //   if (isLogin) {
    //     this.TOKEN = this.storage.getDataField('token');
    //     this.setHeader(this.TOKEN);
    //   } else {
    //     this.setHeader('false');
    //   }
    // });

    this.TOKEN = this.storage.getToken();

    this.router.events.subscribe((res: any) => {
      if (res instanceof NavigationEnd || res instanceof NavigationStart) {
        this.TOKEN = this.storage.getToken();
        this.refreshHeader();
      }
    });
    this.event.isLogin.subscribe((res: boolean) => {
      this.TOKEN = this.storage.getToken();
      this.refreshHeader();
    });
  }

  refreshHeader() {
    if (this.TOKEN !== undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          'x-access-token': this.TOKEN
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }

  async setHeader(TOKEN: string): Promise<any> {
    if (TOKEN !== 'false') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          'x-access-token': TOKEN
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }



  get(path: string, loader: boolean = true, params: HttpParams = new HttpParams()): Observable<any> {
    // console.log(loader);
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }
  getes(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }),
    };
    return this.http.get(`${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }
  post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(catchError(this.formatErrors));
  }

  postMultiData(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data'
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }


  postMultiDataX(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'x-access-token': this.TOKEN
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }



  alert(message: string, type: any, duraion?: number): any {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 5000,
    });
  }

  alertModal(message: string, type: any, CancelButton = false, isIcon?: boolean): any {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: 'Ok'
    });
  }

  private formatErrors(error: any): any {
    return throwError(error.error);
  }

  getArticle(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BLOG_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }
}
