import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  userId: any;
  USER: any;
  backdropStatus:boolean=true;
  imageUrl: any;
  profileData: any;

  constructor(
    private router:Router,
    private storage:StorageService,
    private api:ApiService,
    private event:EventService
  ) { }

  ngOnInit(): void {
    this.USER = this.storage.getUser()
    // console.log(this.USER);
    if(this.USER){
      this.imageUrl = environment.IMAGE_BASE_URL+'/user/'+this.USER?.profile_image;
    }
    this.getDetails();
  }

  getDetails() {
    this.api.get(`user/profile`).subscribe((res: any) => {
      // console.log(res);
      if (res) {
        this.profileData = res.data;
        console.log(this.profileData,'this.profileData');
        
      }
    });
  }

  logOut(){
    this.api.get(`user/logout`).subscribe((res: any) => {
      if (res.status === 200) {
        this.storage.clearToken();
        this.storage.clearUser();
        this.event.setLoginEmmit(false);
        this.api.alert(res.message, 'success');
        this.router.navigate(['/login']);
      }
    });
  }
  getImage(event:any){
    if(event.target.files.length){
      const formData = new FormData()
        formData.append('profile_image',event.target.files[0])
        // formData.append('user_id',this.USER._id)
        this.api.postMultiDataX('user/profile/update',formData).subscribe((res:any)=>{
          if(res.status === 200){
            this.event.updateProfileGet.next(true)
            this.storage.setUser(res.data)
            this.imageUrl = environment.IMAGE_BASE_URL+'/user/'+res?.data?.profile_image;
            this.api.alert(res.message,'success')
            
          }
          else{
            this.api.alert(res.message,'warning')
          }
        })
    }
   
  }

  closePop() {
     document.body.classList.toggle('bodyActive');
    document.getElementById('sideNav')?.classList.add('d_md_none');
    this.backdropStatus=false;

  }

}
