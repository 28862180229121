<div (click)="closePop()" [ngClass]="backdropStatus?'backdrop active':'backdrop'"></div>
<div class="leftMenu">
    <!-- <div class="logo-one">
        <a href="#" >
            <img  src="./assets/images/logo-one.png" class="logo-img" alt=""></a>
        </div> -->
    <div class="dashboard-left-sec p-0">
        <div class="profile-upload">
            <div class="imageupload">
                <img [src]="imageUrl ? imageUrl : './assets/images/round-pf.png'" alt="">
            </div>
            <span class="upload-btn-wrapper">
                <button class="btn"><img src="./assets/images/photo-camera-icon.png" alt=""></button>
                <input type="file" name="myfile" (change)=getImage($event) />
            </span>
            <!-- <h4 class="userName">Basanti Khan</h4> -->
            <h4 class="userName">{{profileData?.first_name}}&nbsp;{{profileData?.last_name}}</h4>
        </div>
        <div class="profile-holder">
            <ul class="new-dashboard">
                <li routerLinkActive="active">
                    <a routerLink="/dashboard" ><img src="./assets/images/dashboard-icon.svg"
                            alt=""> Dashboard</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/profile" ><img src="./assets/images/profile-icon.svg"
                            alt="">Profile</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/change-pass" ><img src="./assets/images/password-icon.svg"
                            alt="">Change Password </a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/requests"><img src="./assets/images/rest-icon.svg"
                            alt="">My Requests</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/favourite"><img src="./assets/images/fav-icon.svg"
                            alt="">My Favourite</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/saved-searches"><img src="./assets/images/save-search.svg" class="img-one4"
                        alt="">Saved Searches</a></li>
                <li>
                    <a (click)="logOut()"><img src="./assets/images/Logout-icon.svg" alt=""> Logout</a>
                </li>
            </ul>
        </div>
    </div>
</div>