// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  BASE_API_URL: "https://alqasr-admin.dedicateddevelopers.us/api/",
  IMAGE_BASE_URL: "https://alqasr-admin.dedicateddevelopers.us/uploads/",
  // ELASTIC_SEARCH_ENDPOINT: 'https://search-subhrada-node-conkavx3o7ho3h76hwfnqafyae.ap-south-1.es.amazonaws.com',
  ELASTIC_SEARCH_ENDPOINT: "https://es.dedicatedresource.net/",
  BLOG_API_URL: "https://dedicateddevelopers.us/alqasr/wp-json/",
  production: false,
  PORTAL_BASE_URL: "https://portal.alqasr-ui.dedicateddevelopers.us/",


  // BASE_API_URL: "http://10.2.0.97:1512/api/",
  // IMAGE_BASE_URL: "http://10.2.0.97:1512/uploads/",
  // // ELASTIC_SEARCH_ENDPOINT: 'https://search-subhrada-node-conkavx3o7ho3h76hwfnqafyae.ap-south-1.es.amazonaws.com',
  // ELASTIC_SEARCH_ENDPOINT: "https://es.dedicatedresource.net/",
  // BLOG_API_URL: "https://dedicateddevelopers.us/alqasr/wp-json/",
  // production: false,
  // PORTAL_BASE_URL: "https://portal.alqasr-ui.dedicateddevelopers.us/",



  // BASE_API_URL: 'https://admin.myqasr.com/api/',
  // IMAGE_BASE_URL: 'https://admin.myqasr.com/uploads/',
  // ELASTIC_SEARCH_ENDPOINT: 'https://es.myqasr.com/',
  // PORTAL_BASE_URL: 'https://portal.myqasr.com/',
  // BLOG_API_URL: 'https://dedicateddevelopers.us/alqasr/wp-json/',
  // production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
