import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoaderModule } from './loader/loader.module';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptor/interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ServicesModule } from './services/services.module';
import { NgxMaskModule } from 'ngx-mask';
import { LoggedInAuthGuard } from './services/auth.guard';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LoaderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCgJV-hjFQXPJ4ISjSSh5nOgk44F7S0LEs'
    }),
    NgxMaskModule.forRoot()
  ],
  providers: [
    ServicesModule,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    LoggedInAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
