import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsLetterComponent } from '@project/modal/news-letter/news-letter.component';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { environment } from 'src/environments/environment.prod';
import language from '../language/language.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  languageContentJson: { name: string, code: boolean }[] = language;
  portalBasePath: string;
  engLang: boolean;
  languageText: any;
  dynamicText: any;
  currentYear: number;
  settings: any;
  constructor(
    private event: EventService,
    private api: ApiService,
    private dialog:MatDialog
  ) {
    this.portalBasePath = environment.PORTAL_BASE_URL;
  }

  ngOnInit(): void {
    this.dynamicText = this.languageContentJson;
    this.event.languageData.subscribe((res: boolean) => {
      this.engLang = res;
      this.setLanguageData();
      this.currentYear = new Date().getFullYear();

    });
    this.getSettingsData();
  }

  getSettingsData(): void {
    // console.log('0670560765');
    this.api.get('setting/all').subscribe((res: any) => {
      // console.log(res, '==========================================');
      this.settings = res.data;
    })
  }

  setLanguageData(): void {
    // let languageData = this.dynamicText;
    if (this.engLang) {
      // languageData = this.dynamicText.translate;
      this.languageText = this.dynamicText.translate;
    } else {
      this.languageText = this.dynamicText;
    }
    // console.log(this.engLang, 'languageData', this.languageText);
  }

  openMdodal(){
    this.dialog.open(NewsLetterComponent)
  }


}
