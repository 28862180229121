<div class="new__news-letter-modal-main-sec">
    <mat-icon [mat-dialog-close]>close</mat-icon>
<div class="container">
    <div class="row new__news-letter-modal">
        <div class="col-md-9">
            <div class="form-group">
                <label for="">Email</label>
              <input type="text" [formControl]="email">  
            </div>
            <mat-error *ngIf="email.touched && email.hasError('required')">Email Required</mat-error>
            <mat-error *ngIf="email.touched && email.hasError('pattern')">Invalid Email</mat-error>
        </div>
        <div class="col-md-3">
            <button class="btn" (click)="onSubscribe()">Subscribe</button>
        </div>
    </div>
</div>
</div>