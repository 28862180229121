<div class="overlaymenu" (click)="toogleBtn()" >&nbsp;</div> 
<header class="header-wrapper">

  <div class="container-fluid">
    <div class="hamberger-holder">
      <button class="hamberger-menu" type="button" (click)="toogleBtn()">
        <i class="fa fa-bars"></i>
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="logo-holder">
      <a routerLink="/home"><img src="./assets/images/logo.png" alt="" /></a>
    </div>
    <nav class="navigation-holder">
      <ul class="navigation">
        <li class="">
          <!--add class 'active'-->
          <button  type="button" appDropdown="menu1" routerLinkActive="active">
            {{ languageText?.menu_search_property }}
            <img src="./assets/images/arrow.png" alt="" />
          </button>
          <ul class="dropdown-box mega-menu" id="menu1">
            <li>
              <a (click)="closeHamMenu()" routerLink="/" class="search-icon"><img src="./assets/images/search.svg" />
                {{ languageText?.sub_menu_new_search }}</a>
              <a (click)="closeHamMenu()" routerLink="/listing" class="search-icon"><img
                  src="./assets/images/properties.svg" />{{ languageText?.sub_menu_all_homes }}</a>
            </li>
            <li>
              <p>{{ languageText?.sub_menu_enter_area_or_address }}</p>
              <!-- <a routerLink="/">Dubai; holiday homes</a>
                            <a routerLink="/">Dubai; farms / forests</a> -->
              <a *ngFor="let item of recentSList" (click)="recentRoute(item?.srchdata)"><img
                  src="./assets/images/reload.svg" />{{item?.name}}</a>

            </li>
          </ul>
        </li>
        <li>
          <button (click)="closeHamMenu()" type="button" appDropdown="menu2" routerLinkActive="active">
            {{ languageText?.menu_sell_property }}
            <img src="./assets/images/arrow.png" alt="" />
          </button>
          <ul class="dropdown-box sub-link" id="menu2">
            <li>
              <a (click)="closeHamMenu()" routerLink="/advertise">{{
                languageText?.sub_menu_advertise_on_my_qasr
              }}</a>
            </li>
            <li>
              <a (click)="closeHamMenu()" routerLink="/guide-to-selling-home">{{
                languageText?.sub_menu_guide_to_sell_property
              }}</a>
            </li>
            <!-- <li><a routerLink="/">Prices</a></li> -->
          </ul>
        </li>
        <li>
          <a (click)="closeHamMenu()" routerLink="/news" routerLinkActive="active">{{
            languageText?.menu_news
          }}</a>
        </li>
        <li>
          <a (click)="closeHamMenu()" routerLink="/build-house" routerLinkActive="active">{{
            languageText?.menu_build_property
          }}</a>
        </li>
        <li>
          <a (click)="closeHamMenu()" routerLink="/new-production" routerLinkActive="active">{{
            languageText?.menu_new_productions
          }}</a>
        </li>
        <!-- <li><a routerLink="/auction" routerLinkActive="active">Auction</a></li> -->
      </ul>
    </nav>
    <div class="profile-holder">
      <ul class="user-nav">
        <li *ngIf="!isLoggedIn">
          <a routerLink="/login">{{ languageText?.menu_sign_in }}</a>
        </li>
        <li *ngIf="!isLoggedIn">
          <a routerLink="/create-account">{{
            languageText?.menu_create_account
          }}</a>
        </li>
        <!-- <li>
                    <button type="button" appDropdown="menu3">En <img src="./assets/images/arrow.png" alt=""></button>
                </li> -->
        <li>
          <button *ngIf="engLang" type="button" appDropdown="menu3" (click)="changeLang('english')">
            Change language
          </button>
          <button *ngIf="!engLang" type="button" appDropdown="menu3" (click)="changeLang('arabic')">
            تغيير اللغة
          </button>
        </li>
        <li *ngIf="isLoggedIn">
          <button type="button" appDropdown="menu5" routerLinkActive="active">
            <p class="my-account-details">
              <span class="abtar-icon">
                <img [src]="imageSrc" onError="this.src='./assets/images/user.png'" alt="" />
              </span>
            </p>
            <img src="./assets/images/arrow.png" alt="" />
          </button>
          <ul class="dropdown-box sub-link" id="menu5">
            <li>
              <a routerLink="/dashboard">{{
                languageText?.after_login_dashboard
              }}</a>
            </li>
            <li>
              <a routerLink="/profile">{{
                languageText?.after_login_profile
              }}</a>
            </li>
            <li>
              <a routerLink="/change-pass">{{
                languageText?.after_login_change_password
              }}</a>
            </li>
            <li>
              <a routerLink="/requests">{{
                languageText?.after_login_my_requests
              }}</a>
            </li>
            <li>
              <a routerLink="/favourite">{{
                languageText?.after_login_my_favourite
              }}</a>
            </li>
            <li>
              <a routerLink="/saved-searches">
                Saved Searches
              </a>
            </li>
            <!-- <li><a routerLink="/change-password">Change Password</a></li> -->
            <li>
              <a (click)="logout()">{{ languageText?.after_login_logout }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</header>
<div class="notification-section" *ngIf="approvalStatus">
  <div *ngIf="approvalStatus === 'Pending'">
    {{ languageText?.after_login_not_approved }}
  </div>
  <div *ngIf="approvalStatus === 'Reject'">
    Your Account verification is failed. Please
    <a (click)="alertModal()">click here</a> to know the reasons.
  </div>
</div>