<footer class="footer-wrapper">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h4>{{ languageText?.footer_menu_contact }}</h4>
          <ul>
            <!-- class="half"-->
            <li>
              <a routerLink="/customer-service-contact">{{
                languageText?.footer_menu_customer_service_and_contact
              }}</a>
            </li>
            <li>
              <a routerLink="/about">{{
                languageText?.footer_menu_about_my_qasr
              }}</a>
            </li>
            <li>
              <a routerLink="/news">{{ languageText?.menu_news }}</a>
            </li>
            <li>
              <a routerLink="/advertise">{{
                languageText?.sub_menu_advertise_on_my_qasr
              }}</a>
            </li>
            <li>
              <a routerLink="/guide-to-selling-home">{{
                languageText?.sub_menu_guide_to_sell_property
              }}</a>
            </li>
            <!-- <li>
              <a routerLink="#">{{ languageText?.footer_menu_work_with_us }}</a>
            </li> -->
            <!-- <li><a routerLink="/article">News</a></li>
                        <li><a routerLink="/auction">Auction</a></li>
                        <li><a routerLink="/add-property">Add Property</a></li>
                        <li><a routerLink="/feature-properties">Feature Prperties</a></li>
                        <li><a routerLink="/auction-details">Auction Details</a></li>
                        <li><a routerLink="/save-home">Save Home</a></li>
                        <li><a routerLink="/list-details">List Details</a></li>
                        <li><a routerLink="/listing">Listing</a></li> -->
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <h4>{{ languageText?.footer_menu_apps }}</h4>
          <ul>
            <li>
              <a href="#"><i class="fa fa-apple"></i>
                {{ languageText?.footer_menu_iphone_and_ipad }}</a>
            </li>
            <li>
              <a href="#"><i class="fa fa-android"></i>
                {{ languageText?.footer_menu_android }}</a>
            </li>
            <!-- <li><a routerLink="/advertise">Advertise on Al Qasr</a></li>
                        <li><a routerLink="/guide-to-selling-home">Guide to selling a home</a></li>
                        <li><a routerLink="/">Rent a home</a></li>
                        <li><a routerLink="/build-house">Build house</a></li>
                        <li><a routerLink="/agent-details">Agent Details</a></li> -->
          </ul>
        </div>
        <div class="col-12 col-md-3 footer-right-panel">
          <h4>{{ languageText?.footer_menu_follow_my_qasr }}</h4>
          <ul>
            <!-- <li>
              <a href="http://eepurl.com/htvP15" target="_blank"><i class="fa fa-envelope"></i>
                {{ languageText?.footer_menu_my_qasr_newsletter }}</a>routerLink="/newsletter"
            </li> -->
            <li>
              <a target="_blank" routerLink="/newsletter" ><i class="fa fa-envelope"></i>
                {{languageText?.footer_menu_my_qasr_newsletter}}</a>
            </li>
            <ng-container *ngFor="let item of settings">
              <li *ngIf="item?.setting_slug === 'facebook'">
                <a [href]="item?.setting_value" target="_blank"><i class="fa fa-facebook"></i> Facebook</a>
              </li>
              <li *ngIf="item?.setting_slug === 'instagram'">
                <a [href]="item?.setting_value" target="_blank"><i class="fa fa-instagram"></i> Instagram</a>
              </li>
              <li *ngIf="item?.setting_slug === 'linkedin'">
                <a [href]="item?.setting_value" target="_blank"><i class="fa fa-linkedin"></i> LinkedIn</a>
              </li>
              <li *ngIf="item?.setting_slug === 'pinterest'">
                <a [href]="item?.setting_value" target="_blank"><i class="fa fa-pinterest-p"></i> Pinterest</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 terms-policy">
          <p>
            <a routerLink="/terms-and-conditions">{{
              languageText?.footer_menu_terms_and_conditions
            }}</a>
            <a routerLink="/privacy-policy">{{
              languageText?.footer_menu_privacy_policy
            }}</a>
            <a href="{{ portalBasePath }}/login">{{
              languageText?.footer_menu_agent_hub
            }}</a>
          </p>
        </div>
        <div class="col-12 col-md-6 copy-right">
          <p>{{ languageText?.footer_copyright_start + currentYear + languageText?.footer_copyright_end}}</p>
        </div>
      </div>
    </div>
  </div>
</footer>