import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.scss']
})
export class NewsLetterComponent implements OnInit {
  email:FormControl
  dd: Object;
  constructor( private http: HttpClient) { }

  ngOnInit(): void {
    this.email = new FormControl("", [
      Validators.required,
      Validators.pattern(/^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ])
  }

  onSubscribe(){
    this.http.post(
      'https://cors-anywhere.herokuapp.com/us10.api.mailchimp.com/3.0/lists/893ff53d1b/members',
      JSON.stringify({email_address: "anything@gmail.com", status: "subscribed"}), 
      {
        headers:
          new HttpHeaders(
            {
              'Access-Control-Allow-Origin' : '*',
              'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
              'Access-Control-Allow-Headers': 'Authorization, Content-Type',
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              "Authorization": "Basic d8cc0df3e5852eec703f83905222b1eb-us10",
            }
          )
      }
    ).pipe( map(res =>{ 
      // console.log(res)
      this.dd = res 
      }) ).subscribe((res:any)=>{
        // console.log(res);
        
      });
    // console.log(this.dd)
  }

}
