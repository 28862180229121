import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HelperModule } from '@project/helper/helper.module';
import { RouterModule } from '@angular/router';
import { ArticleTagMenuComponent } from './article-tag-menu/article-tag-menu.component';
import { LeftPanelComponent } from './left-panel/left-panel.component';


const COMPONENTS = [HeaderComponent, FooterComponent, ArticleTagMenuComponent,LeftPanelComponent];

@NgModule({
  declarations: [
    COMPONENTS,
    ArticleTagMenuComponent,
    
  ],
  exports: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    HelperModule,
    RouterModule
  ]
})
export class SharedModule { }
