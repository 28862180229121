import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { EventService } from './services/event.service';
import { MetaService } from './services/meta.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'MyQasr – UAE’s greatest Real Estate Portal';
  engLang: boolean;
  lat: any;
  lng: any;

  constructor(
    private router: Router,
    private event: EventService,
    private metaService: MetaService,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {
    this.getLocation()
    this.event.languageData.subscribe((res: boolean) => {
      this.engLang = res;
    });
  }

  ngOnInit(): void {
    this.getLocation()
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({ title, description }: Data) => {
          if (title && description) {
            this.metaService.updateTitle(title);
            this.metaService.updateDescription(description);
          } else {
            this.metaService.updateTitle('MyQasr – UAE’s greatest Real Estate Portal');
          }
        })
      ).subscribe();
  }
  getLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          // console.log(position,'poss');
          
          localStorage.setItem('location',JSON.stringify({user_lat:this.lat,user_long:this.lng}))
        }
        
      });
    }
  }
}

