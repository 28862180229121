import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private storage: StorageService
  ) { }
  packageDetails = new BehaviorSubject({});
  getPackageDetails = this.packageDetails.asObservable();

  propertyDetails = new BehaviorSubject({});
  getpropertyDetails = this.propertyDetails.asObservable();

  rocketPackageDetails = new BehaviorSubject({});
  getrocketPackageDetails = this.rocketPackageDetails.asObservable();

  loading = new BehaviorSubject(false)
  login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  private updateProfile = new BehaviorSubject(this.storage.isAuthenticate());
  isupdateProfile = this.updateProfile.asObservable();

  languageData = new BehaviorSubject(false);
  isLng = this.languageData.asObservable();

  recentSearchData = new BehaviorSubject(false);
  recentSearch = this.recentSearchData.asObservable();

  updateProfileGet = new BehaviorSubject(false)

  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }

  setLoginEmmit(isLogin: boolean) {
    return this.login.next(isLogin);
  }

  setUpdateProfileEmmit(update: boolean) {
    return this.updateProfile.next(update);
  }

  setLanguageEmmit(update: boolean) {
    return this.languageData.next(update);
  }

  recentSearchEmmit(update: boolean) {
    return this.recentSearchData.next(update);
  }
}
