import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  TEMP = {
    KEY: '_main_project_auth_1',
    PASSWORD: '90590348534YYIU!@01'
  };
  USER = {
    KEY: '_main_project_auth_0',
    PASSWORD: '!##0895*()?:}95047834&&teq'
  };
  TOKEN = {
    KEY: '_main_project_token_0',
    PASSWORD: '!##0895*()?:}95047834&&teg'
  };
  REMEMBERTOKEN = {
    KEY: '_project_remember_token_0',
    PASSWORD: '!##0895*()?:}95047834&&teh'
  };


  constructor(
    private cookie: CookieService
  ) { }


  public encription(data: any, secret: string): any {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret);
  }
  public decription(data: any, secret: string): any {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  setTempData(DATA: any): any {
    return this.cookie.set(this.TEMP.KEY, this.encription(DATA, this.TEMP.PASSWORD).toString());
  }

  getTempData(): any {
    const DATA = this.cookie.get(this.TEMP.KEY) !== null ? this.cookie.get(this.TEMP.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearTempData(): any {
    return this.cookie.delete(this.TEMP.KEY);
  }

  async setUser(data: any): Promise<any> {
    return this.cookie.set(this.USER.KEY, this.encription(data, this.USER.PASSWORD).toString());
  }

  getUser(): any {
    const DATA = this.cookie.get(this.USER.KEY) !== null ? this.cookie.get(this.USER.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser(): any {
    return this.cookie.delete(this.USER.KEY);
  }

  async setToken(data: any): Promise<any> {
    return this.cookie.set(this.TOKEN.KEY, this.encription(data, this.TOKEN.PASSWORD).toString());
  }
  async rememberToken(data: any): Promise<any> {
    return this.cookie.set(this.REMEMBERTOKEN.KEY, this.encription(data, this.REMEMBERTOKEN.PASSWORD).toString());
  }

  getToken(): any {
    const DATA = this.cookie.get(this.TOKEN.KEY) !== null ? this.cookie.get(this.TOKEN.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TOKEN.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearToken(): any {
    return this.cookie.delete(this.TOKEN.KEY);
  }

  getDataField(type: string): any {
    if (this.getUser() !== undefined && this.getUser()[type] !== undefined) {
      return this.getUser()[type];
    } else {
      return false;
    }
  }

  isAuthenticate(): boolean {
    if (this.getDataField('token') !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  setDataForSearch(data: any) {
    return new Promise((resolve, reject) => {
      this.cookie.set('homeSearchData', this.encription(data, 'auth))754').toString());
      resolve('');
    });
  }
  getDataForSearch() {
    const DATA = this.cookie.get('homeSearchData') !== null ? this.cookie.get('homeSearchData') : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, 'auth))754');
    } else {
      return undefined;
    }
  }
  clearDataForSearch() {
    return this.cookie.delete('homeSearchData');
  }

  setRecentSearch(data: any) {
    return new Promise((resolve, reject) => {
      this.cookie.set('RecentSearchData', this.encription(data, 'auth))754').toString());
      resolve('');
    });
  }
  getRecentSearch() {
    const DATA = this.cookie.get('RecentSearchData') !== null ? this.cookie.get('RecentSearchData') : [];
    if (DATA && DATA !== []) {
      return this.decription(DATA, 'auth))754');
    } else {
      return [];
    }
  }
  clearRecentSearch() {
    return this.cookie.delete('RecentSearchData');
  }

  setSearchEmirates(data: any) {
    return new Promise((resolve, reject) => {
      this.cookie.set('SearchEmirates', this.encription(data, 'auth))754').toString());
      resolve('');
    });
  }
  getSearchEmirates() {
    const DATA = this.cookie.get('SearchEmirates') !== null ? this.cookie.get('SearchEmirates') : [];
    if (DATA && DATA !== []) {
      return this.decription(DATA, 'auth))754');
    } else {
      return [];
    }
  }
  clearSearchEmirates() {
    return this.cookie.delete('SearchEmirates');
  }

  setIdForNewsDetails(data: any) {
    return new Promise((resolve, reject) => {
      this.cookie.set('newsId', this.encription(data, 'auth))754').toString());
      resolve('');
    });
  }
  getIdForNewsDetails() {
    const DATA = this.cookie.get('newsId') !== null ? this.cookie.get('newsId') : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, 'auth))754');
    } else {
      return undefined;
    }
  }

  setFavPopup(data: any) {
    return new Promise((resolve, reject) => {
      this.cookie.set('FavPopupData', data);
      resolve('');
    });
  }
  getFavPopup() {
    const DATA = this.cookie.get('FavPopupData') !== null ? this.cookie.get('FavPopupData') : '';
    return DATA ;
  }

  clearIdForNewsDetails() {
    return this.cookie.delete('newsId');
  }
}
