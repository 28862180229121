import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisabledDirective } from './disabled.directive';
import { DropdownDirective } from './dropdown.directive';
import { TruncateTextPipe } from './truncate-text.pipe';


// Developer CAN USE THIS MODULE FOR Directive/Pipe etc


@NgModule({
  declarations: [
    DisabledDirective,
    DropdownDirective,
    TruncateTextPipe
  ],
  exports: [
    DisabledDirective,
    DropdownDirective
  ],
  imports: [
    CommonModule
  ]
})
export class HelperModule { }
