import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EventService } from './event.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedIn: boolean;
  constructor(
    private event: EventService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.event.isLogin.subscribe((res: boolean): any => {
      // console.log('AuthGuard', res);
      if (!res) {
        this.router.navigate(['/login']);
      }
    });
    return true;
  }

  private rederectToURL(URL: string): any {
    return this.event.setTempEmmit({ rederectURL: URL }).then(() => {
      this.router.navigate(['/login']);
    });
  }

}


@Injectable()
export class LoggedInAuthGuard implements CanActivate {

  constructor(private router: Router, private event: EventService, private storag: StorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.event.isLogin.subscribe((res: boolean): any => {
      // console.log('LoggedInAuthGuard', res);
      if (res) {
        if (this.storag.getUser()) {
          this.router.navigate(['/profile']);
        }
      }
    });
    return true;
  }
}
