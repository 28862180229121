import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';

@Component({
  selector: 'app-article-tag-menu',
  templateUrl: './article-tag-menu.component.html',
  styleUrls: ['./article-tag-menu.component.scss']
})
export class ArticleTagMenuComponent implements OnInit {
  tagList: any;
  engLang: boolean;

  constructor(
    private api: ApiService,
    private event: EventService
  ) { }

  ngOnInit(): void {
    this.event.languageData.subscribe((res: boolean) => {
      this.engLang = res;
    });
    this.getAllCat();
  }

  // getAllTags() {
  //   this.api.getArticle(`wp/v2/tags?count`).subscribe((res: any) => {
  //     if (res) {
  //       this.tagList = res;
  //       // console.log(res);
  //     }
  //   });
  // }

  getAllCat() {
    this.api.get(`article/getarticlecatelist`).subscribe((res: any) => {
      if (res) {
        this.tagList = res.data;
        // console.log(res);
      }
    });
  }

}
