import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';
import { environment } from 'src/environments/environment.prod';
import * as _ from 'underscore';
import language from '../language/language.json';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  languageContentJson: { name: string, code: boolean }[] = language;
  engLang: boolean;
  TOKEN: any;
  isLoggedIn: boolean;
  USER: any;
  imageSrc: string;
  imageBaseUrl: string;
  approvalStatus: string;
  rejectionReson: any;
  dynamicText: any;
  languageText: any;
  recentSList: any;

  // Add class on body
  toogleBtn(): any {
     document.body.classList.toggle('bodyActive');
  }

  closeHamMenu():any {
    document.body.classList.remove('bodyActive')
  }

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private event: EventService,
    private router: Router
  ) {
    this.imageBaseUrl = environment.IMAGE_BASE_URL;
  }

  ngOnInit(): void {
    this.event.updateProfileGet.subscribe((res:boolean)=>{
      if(res){
        this.USER = this.storage.getUser();
        this.imageSrc = this.imageBaseUrl + 'user/' + this.USER?.profile_image;
      }
    })
    this.dynamicText = this.languageContentJson;
    this.TOKEN = this.storage.getToken();
    this.event.isLogin.subscribe(res => {
      this.isLoggedIn = res;
      if (this.isLoggedIn) {
        this.USER = this.storage.getUser();
        if (this.USER) {
          // console.log(this.USER);
          
          this.getProfileDetails();
          if (this.USER?.profile_image) {
            this.imageSrc = this.imageBaseUrl + 'user/' + this.USER?.profile_image;
          } else {
            this.imageSrc = '';
          }
        }
      } else {
        this.imageSrc = '';
        this.approvalStatus = '';
      }
    });
    // console.log(this.TOKEN);
    if (this.TOKEN === undefined) {
      this.isLoggedIn = false;
      this.event.setLoginEmmit(false);
      // this.router.navigate(['/login']);
    } else {
      this.isLoggedIn = true;
      this.event.setLoginEmmit(true);
      // this.USER = this.storage.getUser();
      // this.approvalStatus = this.USER?.approval_status;
      // if (this.approvalStatus === 'Reject') {
      //   this.rejectionReson = this.USER?.reason;
      // }
      this.getProfileDetails();
    }


    this.event.isupdateProfile.subscribe(res => {
      // console.log(res);
      if (res) {
        this.USER = this.storage.getUser();
        if (this.USER) {
          // this.approvalStatus = this.USER?.approval_status;
          // if (this.approvalStatus === 'Reject') {
          //   this.rejectionReson = this.USER?.reason;
          // }
          // console.log(this.approvalStatus);
          this.getProfileDetails();
        }
        if (this.USER && this.USER?.profile_image) {
          this.imageSrc = this.imageBaseUrl + 'user/' + this.USER?.profile_image;
        } else {
          this.imageSrc = '';
        }
      }
    });

    this.router.events.subscribe((evnt: any) => {
      if (evnt instanceof NavigationStart) {
        // Show loading indicator
        ;
      }
      if (evnt instanceof NavigationEnd) {
        // Hide loading indicator
        ;
        if (this.TOKEN && this.TOKEN !== undefined) {
          this.USER = this.storage.getUser();
          if (this.USER && this.USER?.approval_status !== 'Approved') {
            this.getProfileDetails();
          }
        }
      }

      if (evnt instanceof NavigationError) {
        // Hide loading indicator
        ;

        // Present error to user
        // console.log(evnt.error);
      }
    });
    this.event.languageData.subscribe((res: boolean) => {
      this.engLang = res;
      this.setLanguageData();
    });
    this.event.recentSearchData.subscribe((res: boolean) => {
      // console.log(res);
      if (res) {
        this.recentSList = this.storage.getRecentSearch();
        this.recentSList.forEach((item: { name: any; }, index: any) => {
          if (index !== this.recentSList.findIndex((i: { name: any; }) => i.name === item.name)) {
            this.recentSList.splice(index, 1);
          }
        });
      }
    });
    this.recentSList = this.storage.getRecentSearch();
    this.recentSList.forEach((item: { name: any; }, index: any) => {
      if (index !== this.recentSList.findIndex((i: { name: any; }) => i.name === item.name)) {
        this.recentSList.splice(index, 1);
      }
    });
    
  }


  recentRoute(data: any) {
    this.storage.setDataForSearch(data).then(() => {
      this.router.navigate(['/listing']);
    });
  }

  getProfileDetails() {
    this.api.get(`user/profile`).subscribe((res: any) => {
      // console.log(res);
      if (res) {
        this.storage.setUser(res.data);
        this.approvalStatus = res?.data?.approval_status;
        if (this.approvalStatus === 'Reject') {
          this.rejectionReson = res?.data?.reason;
        }
        // console.log(this.approvalStatus);
      } else {
        this.approvalStatus = '';
      }
    });
  }

  changeLang(Language: string) {
    // console.log(Language);
    if (Language === 'arabic') {
      this.engLang = true;
      // console.log(this.dynamicText);
      this.event.setLanguageEmmit(this.engLang);
      // this.languageText = this.dynamicText.translate;
    } else {
      this.engLang = false;
      this.event.setLanguageEmmit(this.engLang);
    }
    this.setLanguageData();
  }

  logout() {
    this.api.get(`user/logout`).subscribe((res: any) => {
      if (res.status === 200) {
        this.storage.clearToken();
        this.storage.clearUser();
        this.event.setLoginEmmit(false);
        this.approvalStatus = '';
        this.api.alert(res.message, 'success');
        this.router.navigate(['/login']);
      }
    });

  }

  alertModal() {
    this.api.alertModal(this.rejectionReson, 'error');
  }

  // code for language change
  // doTranslate(data: any): any {
  //   return _.findWhere(data, { language: 'ar' }).name;
  // }

  setLanguageData(): void {
    // let languageData = this.dynamicText;
    if (this.engLang) {
      // languageData = this.dynamicText.translate;
      this.languageText = this.dynamicText.translate;
    } else {
      this.languageText = this.dynamicText;
    }
    // console.log(this.engLang, 'languageData', this.languageText);

  }
}
